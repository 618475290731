@import "../abstracts/color";
$body-base-height: 100%;
$body-base-font-size: 14px;
$default-control-padding: 3rem;


// New Fonts 2.0
$ag_sourcesans-regular: "SourceSansProRegular";
$ag_sourcesans-semibold: "SourceSansProSemiBold";
$ag_sourcesans-bold: "SourceSansProBold";

$ag_poppins-regular: "PoppinsRegular";
$ag_poppins-medium: "PoppinsMedium";
$ag_poppins-semibold: "PoppinsSemiBold";
$ag_poppins-bold: "PoppinsBold";

/*** golf base color variables ***/
$golf-base-color: $theme-green;
$golf-base-text-color: $black;
$golf-base-white-bg: $white;

$golf-border-color: $gray-lighter;
$golf-button-bg: $gray-lighter;

// Primary header
$golf-primary-header-bg: $black;
$golf-primary-header-fg: $white;

$golf-sub-menu-fg: $text-color-dark;

$golf-secondary-header-bg: $gray-light;

$golf-card-bg: $theme-green-lightest;
$golf-card-holes-bg: $gray-light;

/*************************************************************color variables**************************************************************/
$golf-grey-text-color: $darkGrey;
$golf-secondary-button-border-green: $theme-green;
$golf-secondary-button-border-red: $red;
$user-config-accordian-header: $Zircon;
$box-shadow-black2: $black-opacity-level2;
$box-shadow-black1: $black-opacity-level1;
$box-shadow-black3: $black-opacity-level3;
$border-grey: $gray-light;
$golf-selected-menu-bg-color: $theme-green-lighter;
$lesson-slot-bg-color: $theme-green-lighter;
$golf-header-menu-bg-color: $Zircon;
$table-border-color: $nobel;
$category-background: $lightGrey;
$block-bg-color: $lightGrey;
$header-color: $lightGrey;
$dialog-tab-header-color: $lightGrey2;
$dialog-tab-header-label: $white;

//alert-popup colors
$ag_warning-color: $orange;
$ag_error-color: $red;
$ag_error-bg : $red-lightest;
$ag_success-color: $green;
$ag_base-color:$gold;

$category-background: $lightGrey;
$check-box-outer: $darkGrey;
$toggle-uncheck-color: $ag_grey;

$setting-search-header-bg: $formBackGroundGray;
$search-placeholder-color: $placeholderGrey;
$search-border-color: $gray-light;
$search-section-bg: $gray-lighter;

$disabled-color: $gray-darker;
$button_background: $single_select_background;
$arrow-color: $suvaGrey;
$grid-inactive: $snow;
$schedule-bg: $snow;
$border_color: $white;
$white-bg: $white;
$arrow-disabled: $black-opacity-level25;
$border-color: $black-light;

$actions-overlay: $black-light;
$actions-border: $gray-light;
$actions-disabled-color: $gray-light;
$actions-bg: $gray-lighter;
$golf-dark-text-color: $gray-dark;
$golf-tee-sheet-header: $gray-light;
$golf-spilt-action-border: $gray-light;
$golf-spilt-button-border: $white;
$table-light-bg: $gray-lightest;
/****************************************************************************Golf font variables******************************************************/
// $golf-lato-regular: "LatoRegular";
// $golf-lato-medium: "LatoWebMedium";
// $golf-lato-bold: "LatoWebBold";

$golf-lato-regular: "SourceSansProRegular";
$golf-lato-medium: "SourceSansProSemiBold";
$golf-lato-bold: "SourceSansProBold";


/*********************************Media query breakpoint values************************************************/
$medium-min-width: 480px;
$medium-max-width: 768px;
$small-max-width: 480px;
$large-min-width: 1024px;
$large-max-width: 1350px;

/**************table-values**************/
$five-px: 5px;
$ten-px: 10px;
$fifteen-px: 15px;
$twenty-px: 20px;

/************** common variables **********************/
$default-container-width: 460px;
$default-container-padding: 1.25rem;
$default-footer-height: 67px;
$default-side-panel-width-sm: 200px;
$default-side-panel-width-lg: 250px;
$default-icon-size: 16px;
$default-control-padding: 15px;
$tertiary-header-height: 65px;
$tertiary-header-modified-height: 49px;
$header-height: 65px;
$dialog-header-height: 4rem;
$dialog-footer-height: 4rem;
$color-code-width: 15px;
$color-code-height: 15px;
$toggle-height: 50px;
/** Checkbox variables ***/
$checkbox-ripple-color: $black-light;
$checkbox-size: 16px;
$checkbox-animation-ripple: 600ms;
$checkbox-animation-check: 0.2s;
$checkbox-color: $black;
$disabledCheckboxBorder: $gray-darker;
$default-footer-height: 67px;
$mat-toggle-button-height: 40px;
$icon-button-size: 40px;
$player-detail-height: 40px;
$radio-size: 16px;
// -----card grey----


$golf-card-color: $gray-lighter;
$golf-player-index: $blue-bayoux;
$caption-color: $black-dark;
$players-border-color: $black-very-lightest;
$players-border-color: $black-lightest;
//alert-popup colors
$golf-warning-color: $orange;
$golf-error-color: $red;
$golf-intervalBlock-table-border: $gray;
$golf-dark-bg-color: $black-lightest;

//report
$color-reports-bg: $reports-bgColor;
$color-button-split:$button-split-color;
$color-container-bg: $PureWhite;
$color-border-gold:$green;


//Guest History Table
$history-table-gray: $gray-dim;
$history-table-hover: $theme-green-lightest;
$data-round-border: $gray;
$itnerary-table-th-border: $gray-dark;

//spa variables


$BodyBgColour: $lightwhite;
$ContainerBgColor: $PureWhite;
$label: $DarkOrange;
$SelectionBgColor:$black;
$SelectionColor:$accent;
$color-header:$black;
$color-container-bg: $PureWhite;
$color-header-bg: $lightsemiBlack;
$color-header-text: $PureWhite;
$color-bg-row-header: $lightsemigrey;
$color-text: $wetapsat;
$color-colum-bg: $lightwhite;
$color-logo: $PureWhite;
$color-border-gold:$gold;
$color-border-blue:$blue;
$color-border-red:$DarkOrange;
$color-row-border:$lightraygrey;
$color-base: $pureblack; //Base Color of application
$color-address: $lightgrey;
$color-steeper-bg:$stepper-color;
$color-subheader: $semigrey; //Using Setting Header
$color-Setting-boder: $semilightgreyWhite; //Using Setting Page border
$color-setting-button: $HexaGrey;
$color-button-color: $PureWhite; //Using Setting button color
$color-inner-Header: $HexaLightWhite; //Using Setting Inner Header
$color-online: $StrongGreen;
$color-offline: $StrongRed;
$color-active: $Green;
$color-inactive: $Red;
$color-table-black: $TableBlack; //Using Setting table
$color-header-bg-gold:$Darkgold;
$color-secondary-button-hover: $lightgoldcircle;
$color-secondary-button-pressed: $secondaryButtonPressed;

$setup-header-day-bg: $lightSilverGrey; //Next four colors are Used in for Lunch-setup
$setup-hover-bg: $darkSilver;
$setup-header-bg: $brightGrey;
$color-table-header-bg: $lightSeaBlue;
$color-missing-service: $StrongRed;
$color-up-arrow: $darkGreen;
$color-down-arrow: $darkRed;
$color-yield1-bg: $mintCream;
$color-yield2-bg: $paleYellow;
$color-yield1: $seaBlue;
$color-yield2: $lightOrange;
$color-yield3: $lightGreen;
$color-filter-header-bg: $whiteSmoke;
$color-theme-app:$color-theme;
$color-form-field:$formFieldGray;
$color-form-bgColor:$formBackGroundGray;
$color-tabText-gray: $black;
$color-white-opacity:$whiteOpacity;
$color-light-gray:$grayLight;
$color-appointment:$appointmentColor;
$color-scheduled:$scheduled;
$color-checkedout:$checkedout;
$color-checkedout-bg:$checkedOut-bg;
$color-upcoming:$upcoming-bg;
$color-clients:$clients;
$color-therapist:$therapist;
$color-tab-content-background: $tabBackGroundGray;
$color-darkGray:$darkGray;
$color-gold: $gold;
$color-noShow:$noShow;
$color-border-gray:$borderGray;
$color-border-grey-fullopacity:$border-grey-fullopacity;
$color-border-lightgray:$borderlightGray;
$color-border-gray-opacity5:$border-grey-opacity5;
$color-radio-gray:$grayRadio;
$color-input-holder:$formFieldGray;
$color-row-gray:$recentRowGray;
$color-near-checkout:$nearlyCheckOut;
$color-border-gray:$appoinment-actions-gray;
$color-menu-gray:$appointment-actions-menu;
$color-shop-border:$shop-border-bottom;
$color-white-transparent:$white-transparent;
$color-border-shop:$border-shop;
$color-left-arrow:$left-arrow-gray;
$color-text-color:$textblack;
$color-steeper-bg:$stepper-color;
$color-button-split:$button-split-color;
$color-more-legend:$more-legend;
$color-success-green:$success-green;
$color-addons-border: $gold;
$color-grey-border: $linkWater;
$color-grid-border: $inputPlaceholderGray;
$color-slots:$lightSeaGreen;
$color-female:$neonPink;
$color-male:$ceruleanBlue;
$color-disabled: $HexaWhite;
$color-li-selected:$floralWhite;
$disabled-row: $aliceBlue;
$disabled-button: $grey;
$disabled-select-underline: $lavender;
$color-staff-border:$suvaGrey;
$color-label:$tuna;
$color-logo-gold:$logo-gold;
$color-blac-opac8:$black-opacity8;
$color-btn-hover: $lightyellow;
$color-tertiary-btn-hover: $tertiarygold;
$color-tertiary-btn-press: $tertiarygolddark;
$color-reports-border: $black-opacity;
$table-header-bg: $formBackGroundGray;
$color-blue-color:$warning-blue;
$card-selection-bg-color: $Beige;
$color-tick-green: $tick-color;
$color-cross-color:$cross-color;
$color-therapist-border: $lightGray;
$label-secondary-color: $formFieldGray;
$color-border-primary: $tabTextGray;
$color-border-secondary: $color-light-grey;
$color-button-secondary-disabled: $secondaryButtonGrey;
$color-searchbox-bg:$searchbox-bg;
$color-client-bg: $snow;
$color-therapist-border: $lightGray;
$form-field-color: $lightblack;
$color-th-bg: $thbg;
$color-save-button: $PureWhite;
$mat-tab-labels: $PureWhite;
$reportBG: $formBackGroundGray;
$color-reports-bg:$reports-bgColor;
$color-reports-background: $PureWhite;
$color-grey-resolution:$screen-grey;
$color-resolution-grey: $resolution-grey;
$color-resolution-black: $resolution-black;
$spa-wizard-total-amount-bg: $light-white;
$toggleBg-color:$toggleBG;
$color-shop-bg: $shop-background;
$border-color: $dullgrey;
$split-button-border-color: $formBackGroundGray;

$global-search-header:  $grey-global;
$global-search-white: $white-global;
$global-search-lightyellow: $light-yellow;
$global-search-overlay: $light-grey-global;

$icon-color:$gold;

$package-selected-color: $papayawhip;
/*****Button - Width & Height -******/
$primary-btn-width: 118px;
$primary-btn-height: 40px;
$primary-btn-border-radius: 3px;
$spa-circle-width:28px;
$spa-circle-height:28px;
$spa-small-btn-height:30px;
$spa-inside-tb-btn: 20px;
$spa-lg-btn-height: 34px;
$spa-savebtn-width: 120px;
$spa-savebtn-min-width: 88px;




/*****Font-Size******/

$font-size2: 2px;
$font-size4: 4px;
$font-size6: 6px;
$font-size8: 8px;
$font-size10: 10px;
$font-size12: 12px;
$font-size14: 14px;
$font-size16: 16px;
$font-size18: 18px;
$font-size20: 20px;
$font-size22: 22px;
$font-size28: 28px;
$font-size40: 40px;
$font-size11: 11px;




//****************Font family*****************//


// $lato-web-web: "LatoWeb";
// $lato-web-medium: "LatoWebMedium";
// $lato-web-bold: "LatoWebBold";
$lato-web-web: "SourceSansProRegular";
$lato-web-medium: "SourceSansProSemiBold";
$lato-web-bold: "SourceSansProBold";


//********Form - Field Configuration***************//
$font-size-mat-form-field-input: $font-size14;

$label-color: $custom-label-color;
$value-color: $custom-value-color;
$golf-dark-bg-color: $black-lightest;


/** Dashboard */

$line_tool_tip_bg:$Turquoise;
$line_tooltip_container_border:$Porcelain;
$line_tooltip_header_bg:$ClearDay;
$line_tooltip_content_label:$Zorba;
$weather-text: $Heather;

/* Graphical VIEW*/
$graphicalDrakRow: $mildGreenDarkRow;
$graphicalLightRow: $mildGreenLightRow;



$order-summary-light-bg: $theme-light-bg;
$order-summary-dark-bg: $theme-dark-bg;
$theme-base-color: $gold;
$selected-menu-bg-color: $floralWhite;
$dark-grey-hint-color: $slategray;
$order-summary-highlight-bg: $theme-highlight-bg;
$error-color: $red;
$selected-menu-color:$theme-highlight-bg;

$menu-search-hover-theme:$light-yellow;
$theme-grey-bg-color: $theme-grey-bg;
$mat-input-disabled-color: $black-opacity38;

$theme-popup-header-bg: $white;
$theme-popup-header-fg: $black;
$theme-popup-header-close-bg: $white;

// Common Components Variables
/****************************************ont variables**********************************************************/
// $ag_lato-regular: "LatoRegular";
// $ag_lato-medium: "LatoWebMedium";
// $ag_lato-bold: "LatoWebBold";
$ag_lato-regular: "SourceSansProRegular";
$ag_lato-medium: "SourceSansProSemiBold";
$ag_lato-bold: "SourceSansProBold";

$lato-web-web: "SourceSansProRegular";
$lato-web-medium: $ag_lato-medium;
$lato-web-bold: $ag_lato-bold;
/*********************************Media query breakpoint values************************************************/
$medium-min-width: 480px;
$medium-max-width: 768px;
$small-max-width: 480px;
$large-min-width: 1024px;
$large-max-width: 1350px;

/*********************************Reset Declarations***********************************************************/
$ag_base-height: 100%;
$ag_base-font-size: 14px;

/****************************************Font-Size*************************************************************/
$font-size2: 2px;
$font-size4: 4px;
$font-size6: 6px;
$font-size8: 8px;
$font-size10: 10px;
$font-size11: 11px;
$font-size12: 12px;
$font-size14: 14px;
$font-size16: 16px;
$font-size18: 18px;
$font-size20: 20px;
$font-size22: 22px;
$font-size24: 24px;
$font-size26: 26px;
$font-size28: 28px;
$font-size28: 30px;
$font-size40: 40px;

/************** common variables **********************/
$default-container-width: 460px;
$default-container-padding: 1.25rem;
$mat-toggle-button-height: 40px;
$radio-size: 16px;
$default-side-panel-width-sm: 200px;
$default-side-panel-width-md: 250px;
$default-side-panel-width-lg: 280px;
$default-control-padding: 3rem;
$default-icon-size: 16px;
$tertiary-header-height: 65px;
$header-height: 75px;
$dialog-header-height: 4rem;
$dialog-footer-height: 4rem;
$toggle-height: 50px;
$phone-wrapper-width: 460px;

/*** Agilysys base color variables ***/

$ag_base-text-black: $black;
$ag_base-text-white: $white;

$ag_border-color: $gray-light;
$ag_button-bg: $gray-lighter;

//alert-popup colors
$ag_warning-color: $orange;
$ag_error-color: $red;
$ag_success-color: $green;

// Primary header Level1 Header
$ag_primary-header-bc: $black;
$ag_primary-header-fc: $gray-light;

// Secondary header Level2 Header
$ag_secondary-header-bc: $white;
$ag_secondary-header-fc: $gray-darker;

$ag_black-lighter: $black-lighter;

// Greys
$ag_grey-darkest: $grey-darkest;
$ag_grey-darker: $grey-darker;
$ag_grey-dark: $grey-dark;
$ag_grey-medium1: $grey-medium1;
$ag_grey-medium2: $grey-medium2;
$ag_grey-light: $grey-light;
$ag_grey-lighter: $grey-lighter;
$ag_grey-lightest: $grey-lightest;
$ag_grey_light_bg: $gray-preview-bg;
$ag_grey_border: $gray-bg;
$ag_grey_lightColor: $grey_lighter;

$ag_white: $white;
$ag_black: $black;
$ag_black-light: $black-light;
$ag_black-lighter: $black-lighter;
$ag_black-lightest: $black-lightest;
$ag_navy-blue-light: $navy-blue-light;
$ag_navy-blue-lightest: $navy-blue-lightest;
$ag_navy-blue-lighter: $navy-blue-lighter;
$ag_navy-blue-dark: $navy-blue-dark;


$toggle-uncheck-color: $ag_grey;
// Disabled Color
$ag_disabled-color: $gray-darker;

// //Button Color
$ag_button-primary-fc: $white;

$ag_button-secondary-bc: $white;
$ag_button-secondary2-fc: $gray-dark;
$ag_black-light: $black-light;
$ag_black-lighter: $black-lighter;
$ag_black-lightest: $black-lightest;
$ag_expansion-hdr_bc: $brown-light;
$ag_expansion-hdr_border: $brown-dark;
// // Common Module Variables
$color-shop-border: $gray-light;
$color-base: $black;
$color-header-text: $white;
$color-container-bg: $white;
$color-theme: $white;
$color-light-gray: $gray-light;
$color-border-gray: $gray-light;
$color-disabled: $gray-light;
$color-error-red: $lightest-red;
$color-active: $green;
$color-inactive: $red;
$checkbox-ripple-color: $ag_base-color;
$category-background: $gray-light;
$color-grey-border: $gray-light;

// //CDKTable Component Colors
$ag_border-lighter: $grey-lighter;

// // header and menu variables
$selected-menu-color: $golf-base-color;
$primary-menu-header-bg: $golf-base-color;
// $tertiary-menu-header-bg: "";
$vertical-menu-header-bg: $golf-base-color;
$header-initial-box: $black;
$header-initial-text: $white;
$menu-header-height: 60px;
$theme-container-height: 24px;
$total-header-height: $menu-header-height + $theme-container-height;
$vertical-menu-text: $gray-light;
$ag_confirmation_color: $yellow;
$search-bgColor : $snow;
$color-error-red: $red;
$popover-bg: $white !default;
$dialog-header-bg: $new_spa_primary_40;
$dialog-text-color: $ag_base-text-white;
$guest-img-bg: $aqua-green;
$theme-bg-hover-color: $menu-hover-color;
$create-modal-bg: $gold;
$theme-bg-color: $theme-light-bg;

$summary-bg: $blue-shade3;
$ag_button-primary-bc: $formBackGroundGray;
$menu-header-bg: $text-color-dark;
$menu-header-font-color: $header-font-color;
$secondary-menu-header-height: 49px;

$error-light-red: $light-red;
$ag_button-secondary1-fc: $gold;
// retail table hover css
$ag_table_hover_bg: $light-yellow;
$model-bg: $blue-shade4;
//pms related variables
$ag_expansion-hdr_bc: $brown-light;
$ag_expansion-hdr_border: $brown-dark;

$ag_red: $red;
$light-blue-bg: $theme-blue-lighttest;
$ag_red-lightest: $red-lightest;
$ag_secondary-table-active-color: $violet-lightest;
$listValueBg : $dark_shade14;
$select-bg: $grey-light-bg;
$section-bg-blue: $venice-lighter;
$advancedSearch-bg: $blue-shade5;
$table-row-highlight: $grey-shade5;
$ag_orange-lighter: $orange-lighter;
$ag_green: $green;
$theme-table-bg-color: $table-bg-color;
$theme-table-border-color: $table-border-color;

//version 2.0

$background_grey: $new_background_grey;
$border_grey: $new_border_grey;
$spa_primary_20 : $new_spa_primary_20;
$spa_primary_40 : $new_spa_primary_40;
$ag_green: $green;
$ag_red: $red;
$post-icon-green: $green-shade1;
$spa_primary_10 : $new_spa_primary_10;
$ag_discountRate-bg-color: $discountRate-bg-color;
$primary_bg_color : $new_spa_primary_20;

$ag-bg-lightBlue: $Pms-lightBlue;
$department-bgColor : $grey-shade3;
$department-checked-bgColor: $blue-shade15;
$spa-table-border:$new_spa_table-border;
$spa-table-bg : $new_spa_table-bg;